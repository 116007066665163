<template>
    <div>
        <v-chip
            v-if="+item.acrescimo_valor"
            color="cyan"
            class="white--text ml-2"
            small
        >
            <span>
                + R${{ formatPrice(item.acrescimo_valor) }}
            </span>
        </v-chip>
        <v-chip
            v-if="+item.acrescimo_percentual"
            color="cyan"
            class="white--text ml-2"
            small
        >
            <span>
                + {{ formatPrice(item.acrescimo_percentual) }}%
            </span>
        </v-chip>
        <v-chip
            v-if="+item.desconto_valor"
            color="success"
            class="white--text ml-2"
            small
        >
            <span>
                - R${{ formatPrice(item.desconto_valor) }}
            </span>
        </v-chip>
        <v-chip
            v-if="+item.desconto_percentual"
            color="success"
            class="white--text ml-2"
            small
        >
            <span>
                - {{ formatPrice(item.desconto_percentual) }}%
            </span>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'AcrescimoDescontoChips',

    props: {
        item: {
            type: Object,
        },
    },
}
</script>
