<template>
    <v-select
        @change="$emit('change', formaPagamento)"
        v-model="formaPagamento"
        :items="items"
        label="Forma de pagamento"
        hide-details
        return-object
        item-value="id"
        item-text="descricao"
        outlined
        dense
    >
        <template v-slot:selection="{ item }">
            {{ item.descricao }}
            <AcrescimoDescontoChips
                :item="item"
            />
        </template>
        <template v-slot:item="{ item }">
            {{ item.descricao }}
            <AcrescimoDescontoChips
                :item="item"
            />
        </template>
    </v-select>
</template>

<script>
import AcrescimoDescontoChips from './AcrescimoDescontoChips';

export default {
    name: 'FormaPagamentoSelect',

    components: {
        AcrescimoDescontoChips,
    },

    props: ['value', 'items'],

    data: () => ({
        formaPagamento: null,
    }),
}
</script>
